//import React from 'react';
//import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/images/miyoshi_cd1_preview.png" className="App-logo" alt="CodeDojo Miyoshi logo" />
      </header>
    </div>
  );
}

export default App;
